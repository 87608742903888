import {Fragment, useContext, useRef, useState} from 'react'
import {Dialog, Switch, Transition} from '@headlessui/react'
import {ApiConsumer} from "../../api/ApiConsumer";
import {API_ROUTES} from "../../routes/api";
import {LoaderContext} from "../../contexts/loader-context";
import {useUtils} from "../../hooks/utils-hook";

export default function BannerModal({
                                        banner = {}, open, setOpen, refresh = () => {
    }
                                    }) {
    const [details, setDetails] = useState(banner)

    let {setLoading} = useContext(LoaderContext)
    let {BuildFormData} = useUtils()
    const cancelButtonRef = useRef(null)

    function submit() {
        setLoading(true)

        if (Object.keys(banner).length === 0) {
            ApiConsumer.post(API_ROUTES.ADMINISTRATION.BANNERS.LIST, BuildFormData(details))
                .then(() => {
                    setOpen(false)
                    refresh()
                })
                .catch(err => console.error(err))
                .finally(() => setLoading(false))
        } else {
            ApiConsumer.put(API_ROUTES.ADMINISTRATION.BANNERS.DETAIL(banner?.id), BuildFormData(details))
                .then(() => {
                    setOpen(false)
                    refresh()
                })
                .catch(err => console.error(err))
                .finally(() => setLoading(false))
        }
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <form onSubmit={event => {
                                    event.preventDefault()
                                    submit()
                                }}>
                                    <div className="mt-1 sm:mt-1">
                                        <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                            {banner.title ?? 'Add new banner'}
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                                                <div>
                                                    <label htmlFor="company-website"
                                                           className="block text-sm font-medium text-gray-700">
                                                        Title
                                                    </label>
                                                    <div
                                                        className="mt-1 rounded-md shadow-sm col-span-4 sm:col-span-2">
                                                        <input
                                                            type="text"
                                                            name="company-website"
                                                            id="company-website"
                                                            className="block w-full rounded border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                            placeholder="Banner title"
                                                            value={details?.title}
                                                            onChange={event => {
                                                                setDetails((prevState) => ({
                                                                    ...prevState,
                                                                    [`title`]: event.target.value,
                                                                }))
                                                            }}
                                                        />
                                                    </div>
                                                </div>


                                                <div>
                                                    <label htmlFor="about"
                                                           className="block text-sm font-medium text-gray-700">
                                                        Description
                                                    </label>
                                                    <div className="mt-1">
                                                              <textarea
                                                                  id="about"
                                                                  name="about"
                                                                  rows={3}
                                                                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                                  placeholder="Description of banner"
                                                                  defaultValue={''}
                                                                  value={details?.description}
                                                                  onChange={event => {
                                                                      setDetails((prevState) => ({
                                                                          ...prevState,
                                                                          [`description`]: event.target.value,
                                                                      }))
                                                                  }}
                                                              />
                                                    </div>
                                                </div>

                                                <div>
                                                    <label
                                                        className="block text-sm font-medium text-gray-700">Image</label>
                                                    <div
                                                        className="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                                                        <div className="space-y-1 text-center">

                                                            {
                                                                banner?.image ? <img className='mx-auto h-20 w-32'
                                                                                     src={banner?.image}
                                                                                     alt={'Banner image'}/> : <svg
                                                                    className="mx-auto h-12 w-12 text-gray-400"
                                                                    stroke="currentColor"
                                                                    fill="none"
                                                                    viewBox="0 0 48 48"
                                                                    aria-hidden="true"
                                                                >
                                                                    <path
                                                                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                                        strokeWidth={2}
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    />
                                                                </svg>
                                                            }

                                                            <div className="flex text-sm text-gray-600">
                                                                <label
                                                                    htmlFor="file-upload"
                                                                    className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
                                                                >
                                                                    <span>{banner?.image ? 'Change' : 'Upload a'} file</span>
                                                                    <input id="file-upload" name="file-upload"
                                                                           type="file" className="sr-only"
                                                                           onChange={event => {
                                                                               setDetails((prevState) => ({
                                                                                   ...prevState,
                                                                                   [`image`]: event.target.files[0],
                                                                               }))
                                                                           }
                                                                           }/>
                                                                </label>
                                                                <p className="pl-1 mt-0.5 text-xs text-gray-500">PNG,
                                                                    JPG, GIF up to 10MB</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div>
                                                    <label htmlFor="company-website"
                                                           className="block text-sm font-medium text-gray-700">
                                                        Button Text
                                                    </label>
                                                    <div
                                                        className="mt-1 rounded-md shadow-sm col-span-4 sm:col-span-2">
                                                        <input
                                                            type="text"
                                                            name="company-website"
                                                            id="company-website"
                                                            className="block w-full rounded border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                            placeholder="Button text"
                                                            value={details?.button_text}
                                                            onChange={event => {
                                                                setDetails((prevState) => ({
                                                                    ...prevState,
                                                                    [`button_text`]: event.target.value,
                                                                }))
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                <div>
                                                    <label htmlFor="company-website"
                                                           className="block text-sm font-medium text-gray-700">
                                                        Action
                                                    </label>
                                                    <div
                                                        className="mt-1 rounded-md shadow-sm col-span-4 sm:col-span-2">
                                                        <input
                                                            type="text"
                                                            name="company-website"
                                                            id="company-website"
                                                            className="block w-full rounded border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                            placeholder="Action"
                                                            value={details?.action}
                                                            onChange={event => {
                                                                setDetails((prevState) => ({
                                                                    ...prevState,
                                                                    [`action`]: event.target.value,
                                                                }))
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className='grid gap-4 grid-cols-2'>
                                                    <div>
                                                        <label
                                                            className="block text-sm font-medium text-gray-700">Active</label>
                                                        <div
                                                            className="mt-1 flex rounded-md pb-6">
                                                            <div className="space-y-1">

                                                                <Switch
                                                                    checked={details?.active ?? false}
                                                                    onChange={checked => {
                                                                        setDetails((prevState) => ({
                                                                            ...prevState,
                                                                            [`active`]: checked,
                                                                        }))
                                                                    }}
                                                                    className={`${
                                                                        details?.active ? 'bg-emerald-500' : 'bg-rose-500'
                                                                    } relative inline-flex h-6 w-11 items-center rounded-full`}
                                                                >
                                                                    <span className="sr-only">Enable visibility</span>
                                                                    <span
                                                                        className={`${
                                                                            details?.active ? 'translate-x-6' : 'translate-x-1'
                                                                        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                                                    />
                                                                </Switch>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <label
                                                            className="block text-sm font-medium text-gray-700">Service</label>
                                                        <div
                                                            className="mt-1 flex rounded-md pb-6">
                                                            <div className="space-y-1">

                                                                <Switch
                                                                    checked={details?.service ?? false}
                                                                    onChange={checked => {
                                                                        setDetails((prevState) => ({
                                                                            ...prevState,
                                                                            [`service`]: checked,
                                                                        }))
                                                                    }}
                                                                    className={`${
                                                                        details?.service ? 'bg-emerald-500' : 'bg-rose-500'
                                                                    } relative inline-flex h-6 w-11 items-center rounded-full`}
                                                                >
                                                                    <span className="sr-only">Enable visibility</span>
                                                                    <span
                                                                        className={`${
                                                                            details?.service ? 'translate-x-6' : 'translate-x-1'
                                                                        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                                                    />
                                                                </Switch>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>

                                    <div
                                        className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                        <button
                                            type="submit"
                                            className="inline-flex w-full justify-center rounded-md border border-transparent bg-emerald-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-emerald-700 sm:col-start-2 sm:text-sm"
                                        >
                                            Save
                                        </button>
                                        <button
                                            type="button"
                                            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-rose-400 hover:text-white sm:col-start-1 sm:mt-0 sm:text-sm"
                                            onClick={() => setOpen(false)}
                                            ref={cancelButtonRef}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </form>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
