import {APP_ID} from "config";

function store_value(key, value) {
    localStorage.setItem(key, JSON.stringify(value))
}

function get_value(key) {
    let res = localStorage.getItem(key)
    if (res !== undefined) {
        return JSON.parse(res)
    }
    return null
}

function clear_storage() {
    localStorage.clear()
}


export const useAuthentication = () => {
    function currentUser() {
        return get_value(APP_ID)
    }

    function isAuthenticated() {
        return true;//!!localStorage.getItem(APP_ID);
        //TODO: SET THIS TO TRUE IF NOT CONNECTED TO AUTH API
    }

    function hasPermission(permission) {
        if (currentUser() !== null) {
            let permissions = currentUser().permissions;
            if (permissions === undefined) {
                clear_storage()
                return true
            }
            let perm = permissions.find(o => o.name === permission);
            return perm !== undefined;
        }
        return true
    }

    function setAuthentication(details) {
        store_value(APP_ID, details)
    }

    function logout() {
        clear_storage()
        window.location.reload();
    }


    return {currentUser, setAuthentication, logout, hasPermission, isAuthenticated}
}
