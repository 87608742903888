import {useEffect, useState} from "react";
import {ApiConsumer} from "../../../api/ApiConsumer";
import {API_ROUTES} from "../../../routes/api";
import Select from "react-select";

export function Shipping({details = {}, setDetails}) {

    return <>
        <form className="space-y-6 sm:px-6 lg:col-span-9 lg:px-0 border-2 border-gray-300 rounded mt-2"
              method="POST">
            <div className="shadow sm:overflow-hidden sm:rounded-md">
                <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
                    <div>
                        <h3 className="text-lg font-medium leading-6 text-gray-900">Shipping</h3>
                    </div>

                    <div className="grid grid-cols-6 gap-6">
                        <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                            <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                                Height
                            </label>
                            <div className="mt-1 flex rounded-md shadow-sm">
                                <input
                                    type="number"
                                    className="block w-full flex-1 rounded-none rounded-l-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    placeholder="0"
                                    onChange={event => {
                                        setDetails((prevState) => ({
                                            ...prevState,
                                            [`height`]: event.target.value,
                                        }))
                                    }}
                                    value={details?.height}
                                />
                                <span
                                    className="inline-flex items-center rounded-r-md border border-l-0 border-gray-300 bg-gray-50 px-3 text-sm text-gray-500">
                                    cm
                                </span>
                            </div>
                        </div>

                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                            <label htmlFor="region" className="block text-sm font-medium text-gray-700">
                                Length
                            </label>
                            <div className="mt-1 flex rounded-md shadow-sm">
                                <input
                                    type="number"
                                    className="block w-full flex-1 rounded-none rounded-l-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    placeholder="0"
                                    onChange={event => {
                                        setDetails((prevState) => ({
                                            ...prevState,
                                            [`length`]: event.target.value,
                                        }))
                                    }}
                                    value={details?.length}
                                />
                                <span
                                    className="inline-flex items-center rounded-r-md border border-l-0 border-gray-300 bg-gray-50 px-3 text-sm text-gray-500">
                                    cm
                                </span>
                            </div>
                        </div>

                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                            <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700">
                                Weight
                            </label>
                            <div className="mt-1 flex rounded-md shadow-sm">
                                <input
                                    type="number"
                                    className="block w-full flex-1 rounded-none rounded-l-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    placeholder="0"
                                    onChange={event => {
                                        setDetails((prevState) => ({
                                            ...prevState,
                                            [`weight`]: event.target.value,
                                        }))
                                    }}
                                    value={details?.weight}
                                />
                                <span
                                    className="inline-flex items-center rounded-r-md border border-l-0 border-gray-300 bg-gray-50 px-3 text-sm text-gray-500">
                                    kg
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </>
}
