import {useContext, useEffect, useState} from "react";
import {PageTitleContext} from "../contexts/page-title-context";
import {CrumbsContext} from "../contexts/crumbs-context";
import {ApiConsumer} from "../api/ApiConsumer";
import {API_ROUTES} from "../routes/api";
import {PlusCircleIcon} from "@heroicons/react/outline";
import {AddOnCard} from "../components/cards/addon";
import AddOnModal from "../components/modals/addon-modal";
import Loader from "../components/loader";

export default function Addons() {
    const [addons, setAddons] = useState([])
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    let {setTitle} = useContext(PageTitleContext)
    let {setCrumbs} = useContext(CrumbsContext)

    function fetch() {
        ApiConsumer.get(API_ROUTES.INVENTORY.INVENTORY.ADDONS_LIST)
            .then(res => setAddons(res.data))
            .catch(err => console.error(err))
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        setTitle('Add On Costs')
        setCrumbs([{'name': 'Inventory', 'link': '/inventory'}, {'name': 'Add On Costs', 'link': '/inventory/addons'}])

        fetch()
    }, [])
    return <>
        <div className="md:flex md:items-center md:justify-between mb-6">
            <div className="min-w-0 flex-1"/>
            <div className="mt-4 flex md:mt-0 md:ml-4">
                <PlusCircleIcon
                    onClick={() => setOpen(true)}
                    className="h-12 cursor-pointer text-emerald-500 px-4 py-2 text-sm font-medium hover:text-emerald-700"/>
            </div>
        </div>
        <AddOnModal setOpen={setOpen} open={open} refresh={fetch}/>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-6 xl:grid-cols-8">
            {
                loading ? <div className='col-span-8'>
                        <Loader full_height={true}/>
                    </div> :
                    addons.map((addon, i) => {
                        return <AddOnCard key={i} addon={addon} refresh={fetch}/>
                    })
            }
        </div>
    </>
}
