import Login from "../pages/login";
import {
    HomeIcon,
    BadgeCheckIcon,
    OfficeBuildingIcon,
    ColorSwatchIcon,
    ShoppingBagIcon,
    ShoppingCartIcon,
    ThumbUpIcon,
    BriefcaseIcon,
    BookmarkIcon,
    CashIcon,
    UsersIcon,
    PresentationChartBarIcon,
    MapIcon
} from "@heroicons/react/outline";
import Home from "../pages/home";
import { SIDEBAR_ROUTES } from "./sidebar";
import Brands from "../pages/brands";
import Suppliers from "../pages/suppliers";
import Categories from "../pages/categories";
import Users from "../pages/users";
import Banners from "../pages/banners";
import Delivery from "../pages/delivery";
import Inventory from "../pages/inventory";
import InventoryDetail from "../pages/inventory-detail";
import Addons from "../pages/addons";
import Orders from "../pages/orders";
import OrderDetail from "pages/order-detail";
import OrdersMapPage from "../pages/orders-map";

export const APP_ROUTES = [
    {
        name: 'Login',
        link: '/login',
        page: Login,
        exact: true,
        private: false,
        sidebar: false,
        icon: HomeIcon
    },
    {
        name: 'Login',
        link: '/register',
        page: Login,
        exact: true,
        private: false,
        sidebar: false,
        icon: HomeIcon
    },
    {
        name: 'Home',
        link: '/',
        page: Home,
        exact: true,
        private: true,
        sidebar: true,
        icon: HomeIcon
    },
    {
        name: 'Brands',
        link: '/inventory/brands',
        page: Brands,
        exact: true,
        private: true,
        sidebar: true,
        icon: BadgeCheckIcon,
        sidebar_route: SIDEBAR_ROUTES.INVENTORY
    },
    {
        name: 'Suppliers',
        link: '/inventory/suppliers',
        page: Suppliers,
        exact: true,
        private: true,
        sidebar: true,
        icon: OfficeBuildingIcon,
        sidebar_route: SIDEBAR_ROUTES.INVENTORY
    },
    {
        name: 'Inventory',
        link: '/inventory',
        page: Inventory,
        exact: true,
        private: true,
        sidebar: true,
        icon: ColorSwatchIcon,
        sidebar_route: SIDEBAR_ROUTES.INVENTORY
    }, {
        name: 'Inventory',
        link: '/inventory/:id',
        page: InventoryDetail,
        exact: true,
        private: true,
        sidebar: false,
        icon: ColorSwatchIcon,
        sidebar_route: SIDEBAR_ROUTES.INVENTORY
    }, {
        name: 'Add On Costs',
        link: '/addons',
        page: Addons,
        exact: true,
        private: true,
        sidebar: true,
        icon: CashIcon,
        sidebar_route: SIDEBAR_ROUTES.INVENTORY
    },
    {
        name: 'Orders',
        link: '/orders',
        page: Orders,
        exact: true,
        private: true,
        sidebar: true,
        icon: ShoppingBagIcon,
        sidebar_route: SIDEBAR_ROUTES.STORE
    },
    {
        name: 'Orders',
        link: '/order/:id',
        page: OrderDetail,
        exact: true,
        private: true,
        sidebar: false,
        icon: ShoppingBagIcon,
        sidebar_route: SIDEBAR_ROUTES.STORE
    },
    {
        name: 'Orders Map',
        link: '/orders-map',
        page: OrdersMapPage,
        exact: true,
        private: true,
        sidebar: true,
        icon: MapIcon,
        sidebar_route: SIDEBAR_ROUTES.STORE
    },
    // {
    //     name: 'Rewards',
    //     link: '/rewards',
    //     page: Home,
    //     exact: true,
    //     private: true,
    //     sidebar: true,
    //     icon: ThumbUpIcon,
    //     sidebar_route: SIDEBAR_ROUTES.STORE
    // },
    // {
    //     name: 'Vouchers',
    //     link: '/vouchers',
    //     page: Home,
    //     exact: true,
    //     private: true,
    //     sidebar: true,
    //     icon: BriefcaseIcon,
    //     sidebar_route: SIDEBAR_ROUTES.STORE
    // },
    {
        name: 'Categories',
        link: '/admin/categories',
        page: Categories,
        exact: true,
        private: true,
        sidebar: true,
        icon: BookmarkIcon,
        sidebar_route: SIDEBAR_ROUTES.ADMINISTRATION
    },
    // {
    //     name: 'Sub-categories',
    //     link: '/sub-categories',
    //     page: Home,
    //     exact: true,
    //     private: true,
    //     sidebar: true,
    //     icon: BookOpenIcon,
    //     sidebar_route: SIDEBAR_ROUTES.ADMINISTRATION
    // },
    {
        name: 'Users',
        link: '/admin/users',
        page: Users,
        exact: true,
        private: true,
        sidebar: true,
        icon: UsersIcon,
        sidebar_route: SIDEBAR_ROUTES.ADMINISTRATION
    },
    {
        name: 'Banners',
        link: '/admin/banners',
        page: Banners,
        exact: true,
        private: true,
        sidebar: true,
        icon: PresentationChartBarIcon,
        sidebar_route: SIDEBAR_ROUTES.ADMINISTRATION
    },
    // {
    //     name: 'Customers',
    //     link: '/customers',
    //     page: Home,
    //     exact: true,
    //     private: true,
    //     sidebar: true,
    //     icon: UserGroupIcon,
    //     sidebar_route: SIDEBAR_ROUTES.ADMINISTRATION
    // }
]
