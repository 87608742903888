import {useContext, useEffect} from 'react'
import {Redirect, Route, useLocation} from "react-router-dom";
import {useAuthentication} from "hooks/AuthenticationHook";
import {AuthenticationContext} from 'contexts/AuthenticationContext'
import {PORTAL} from "routes/portal";
import PageLayout from "../layouts/page-layout";

export default function PrivateRoute({component: Component, ...rest}) {
    let {isAuthenticated} = useAuthentication()
    let {user, setUser} = useContext(AuthenticationContext)
    const location = useLocation();

    let {currentUser} = useAuthentication()

    useEffect(() => {
        if (Object.keys(currentUser() ?? {}).length > 0) {
            setUser(currentUser())
        }
    }, [])

    return <Route {...rest} render={props => isAuthenticated() && Object.keys(currentUser() ?? {}).length !== 0 ?
        <PageLayout component={<Component {...props} />}/>
        :
        <Redirect to={{pathname: PORTAL.LOGIN, state: {referrer: location.pathname}}} from={location.pathname}/>
    }/>
}

