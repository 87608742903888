const BASE_URL = "https://api.wienergi.com/api"
const VERSION = "v1"
const API_BASE = `${BASE_URL}/${VERSION}`

export const API_ROUTES = {
    AUTHENTICATION: {
        LOGIN: `${API_BASE}/users/login/`,
        PASSWORD_TOKEN: `${API_BASE}/users/password/token`,
        FORGOT_PASSWORD: `${API_BASE}/users/password/reset`,
    },
    INVENTORY: {
        BRANDS: {
            LIST: `${API_BASE}/inventory/brands/`,
            DETAIL: function (brand_id) {
                return `${API_BASE}/inventory/brands/${brand_id}`
            }
        },
        SUPPLIERS: {
            LIST: `${API_BASE}/inventory/suppliers/`,
            DETAIL: function (brand_id) {
                return `${API_BASE}/inventory/suppliers/${brand_id}`
            }
        },
        INVENTORY: {
            LIST: `${API_BASE}/inventory/stock/`,
            DETAIL: function (inventory_id) {
                return `${API_BASE}/inventory/stock/${inventory_id}/`
            },
            ADDONS_LIST: `${API_BASE}/addons/`,
            ADDONS_DETAIL: function (addon_id) {
                return `${API_BASE}/addons/${addon_id}/`
            }
        },

    },
    ADMINISTRATION: {
        CATEGORIES: {
            LIST: `${API_BASE}/inventory/categories/`,
            DETAIL: function (brand_id) {
                return `${API_BASE}/inventory/categories/${brand_id}`
            }
        },
        USERS: {
            LIST: `${API_BASE}/users/`,
            DETAIL: function (user_id) {
                return `${API_BASE}/users/profile/${user_id}`
            },
            TYPES: `${API_BASE}/users/types/`,
            STATUS: `${API_BASE}/users/status/`,
        },
        BANNERS: {
            LIST: `${API_BASE}/banners/`,
            DETAIL: function (brand_id) {
                return `${API_BASE}/banners/${brand_id}`
            }
        }
    },
    STORE: {
        ORDERS: {
            LIST: `${API_BASE}/orders/`,
            DETAIL: function (order_id) {
                return `${API_BASE}/orders/detail/${order_id}/`
            }
        }
    }
}
