import {GoogleMap, MarkerF, useLoadScript} from "@react-google-maps/api";
import {useMemo, useRef} from "react";
import {mapStyle} from "../../assets/styles/map-styles";
import AGENT_ICON from "../../assets/icons/agent-marker.png"
import CUSTOMER_ICON from "../../assets/icons/you-marker.png"
import {useHistory} from "react-router-dom";

export function OrdersMap({orders}) {
    const history = useHistory();
    const inputRef = useRef();

    const {isLoaded} = useLoadScript({
        googleMapsApiKey: "AIzaSyCzGMzh6rNBPkZZERfkjS9vnxRi8dvZxCg",
        libraries: ['places']
    })

    const mapCenter = useMemo(
        () => ({lat: -26.000540, lng: 28.113490}),
        []
    );

    if (!isLoaded) {
        return <div className='h-screen'>
            <p>Loading...</p>
        </div>
    }

    return <>
        <div
            className="h-screen border-b border-r border-l border-gray-200 bg-white shadow-sm sm:rounded-b-lg sm:border-r sm:border-l mb-28 ">
            <GoogleMap
                zoom={6.01}
                options={{
                    disableDefaultUI: true,
                    zoomControl: false,
                    styles: mapStyle,
                }}
                center={mapCenter}
                // mapTypeId={google?.maps.MapTypeId.ROADMAP}
                mapContainerStyle={{width: '100%', height: '100%'}}
            >
                {
                    orders.map(order => {
                        return <MarkerF
                            key={order.id}
                            position={{lat: parseInt(order.latitude), lng: parseInt(order.longitude)}}
                            onClick={(event) => history.push(`/order/${order?.id}`)}
                            icon={CUSTOMER_ICON}
                        />
                    })
                }
            </GoogleMap>
        </div>
    </>
}
