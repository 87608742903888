import React from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import PageView from "components/page-view";
import GoogleAnalytics from "components/google-analytics";
import PrivateRoute from "./routes/private-route";
import {useHistory} from "react-router-dom/cjs/react-router-dom";
import {APP_ROUTES} from "./routes/app";
import {AuthenticationProvider} from "./contexts/AuthenticationContext";
import {PageTitleProvider} from "./contexts/page-title-context";
import {CrumbsContextProvider} from "./contexts/crumbs-context";
import {LoaderContextProvider} from "./contexts/loader-context";

export default function App() {
    let history = useHistory();
    return <>
        <AuthenticationProvider>
            <PageTitleProvider>
                <CrumbsContextProvider>
                    <LoaderContextProvider>
                        <Router history={history}>
                            <PageView/>
                            <GoogleAnalytics/>
                            <Switch>
                                {
                                    APP_ROUTES.map((route, index) => {
                                        return route.private ?
                                            <PrivateRoute key={index} exact={route.exact} path={route.link}
                                                          component={route.page}/>
                                            : <Route key={index} exact={route.exact} path={route.link}
                                                     component={route.page}/>
                                    })
                                }
                            </Switch>
                        </Router>
                    </LoaderContextProvider>
                </CrumbsContextProvider>
            </PageTitleProvider>
        </AuthenticationProvider>
    </>
}
