import {CheckCircleIcon, XCircleIcon} from "@heroicons/react/outline";
import {useState} from "react";
import AddOnModal from "../modals/addon-modal";

export function AddOnCard({addon, refresh=()=>{}}){
    const [open,setOpen] = useState(false)
    return <>
            <AddOnModal addon={addon} setOpen={setOpen} open={open} refresh={refresh} />
            <div onClick={()=>setOpen(true)} className={`text-white w-full max-w-md flex flex-col rounded shadow-lg p-4 cursor-pointer border-2 ${open ? 'border-emerald-500' : 'border-gray-300'} duration-200 hover:scale-105`}>
                <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-4">
                        <div className="text-md font-bold text-gray-500">{`${addon?.name}`}</div>
                    </div>
                    <div className="flex items-center space-x-4">
                        <div className="cursor-pointer">
                            {
                                addon?.active ? <CheckCircleIcon className='text-emerald-500 h-6'/>
                                    : <XCircleIcon className='text-rose-500 h-6'/>
                            }
                        </div>
                    </div>
                </div>
                <div className="text-sm text-gray-400">{`${addon?.percentage ? '' : 'R'}${addon?.cost}${addon?.percentage ? '%' : ''}` }</div>
            </div>
    </>
}
