import {CheckCircleIcon, XCircleIcon} from "@heroicons/react/outline";
import {useState} from "react";
import BannerModal from "../modals/banner-modal";

export function BannerCard({banner, refresh}) {
    const [open, setOpen] = useState(false)

    return <>
        <BannerModal banner={banner} setOpen={setOpen} open={open} refresh={refresh} />
        <div className={`w-full rounded duration-200 hover:scale-105 cursor-pointer border-2 ${open? 'border-green-500': 'border-gray-300'}`} onClick={()=>setOpen(true)}>
            <div className="bg-white shadow-lg hover:shadow-xl rounded-lg ">
                <div className="bg-gray-400 h-64 rounded-t p-4 bg-no-repeat bg-center bg-cover"
                     style={{backgroundImage: `url(${banner?.image}`}}>
                    <div className="text-right">
                        <button className="text-pink-500 hover:text-pink-600 p-2 rounded-full"
                                style={{background: "rgba(0,0,0,0.3)"}}>
                            {
                                banner?.active ? <CheckCircleIcon className='text-emerald-500 h-6'/>
                                    : <XCircleIcon className='text-rose-500 h-6'/>
                            }
                        </button>
                    </div>
                </div>
                <div className="flex justify-between items-start px-2 pt-2">
                    <div className="p-2 flex-grow">
                        <h1 className="font-medium text-xl font-poppins">{banner?.title}</h1>
                        <p className="text-gray-500 font-nunito">{banner?.description}</p>
                    </div>
                    <div className="p-2 text-right mb-2">
                        <span
                            className={`inline-flex items-center rounded-md ${banner?.service ? 'bg-orange-200' : 'bg-emerald-400'} px-2.5 py-0.5 text-sm font-medium text-gray-600`}>
                        <svg className="-ml-0.5 mr-1.5 h-2 w-2 text-gray-600" fill="currentColor" viewBox="0 0 8 8">
                          <circle cx={4} cy={4} r={3}/>
                        </svg>
                            {banner?.service ? 'Service' : 'Product'}
                      </span>
                    </div>
                </div>
            </div>
        </div>
    </>
}
