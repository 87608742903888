import {useContext, useEffect} from "react";
import {PageTitleContext} from "../contexts/page-title-context";
import {CrumbsContext} from "../contexts/crumbs-context";
import {InventoryStats} from "../components/stats/inventory-stats";

export default function Home() {
    let {setTitle} = useContext(PageTitleContext)
    let {setCrumbs} = useContext(CrumbsContext)

    useEffect(() => {
        setTitle('Dashboard')
        setCrumbs([])
    }, [])
    return <>
        <section className="grid grid-cols-1 sm:grid-cols-4 gap-4 mt-5">
            <div className="sm:col-span-4">
                <h3 className='mb-2 font-semibold profile-overview-title'>Inventory Statistics</h3>
                <InventoryStats/>
            </div>
        </section>
    </>
}
