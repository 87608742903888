import {CardContainer} from "./container";
import {CheckCircleIcon, XCircleIcon} from "@heroicons/react/outline";
import {useState} from "react";
import CommerceModal from "../modals/commerce-modal";

export function CommerceCard({entity, title, url, detail_url, refresh, service=false}) {
    const [open, setOpen] = useState(false)

    return <>
        <CommerceModal entity={entity} open={open} setOpen={setOpen} url={url} refresh={refresh} detail_url={detail_url} service={service}/>
        <CardContainer open={open}>
            <div onClick={() => setOpen(true)}>
                <img
                    src={entity?.thumbnail}
                    alt={entity?.name} className="h-80 w-72 object-cover rounded-t"/>
                <div className="px-4 py-3 w-72">
                    <span className="text-emerald-500 mr-3 uppercase text-xs">{entity?.service ? 'Service': title}</span>
                    <div className="flex items-center">
                        <p className="text-lg font-bold text-black truncate block capitalize">{entity.name}</p>
                        <div className="ml-auto">
                            {
                                entity?.active ? <CheckCircleIcon className='text-emerald-500 h-8'/>
                                    : <XCircleIcon className='text-rose-500 h-8'/>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </CardContainer>
    </>
}
