import { Switch } from "@headlessui/react";
import { useEffect, useState } from "react";
import { ApiConsumer } from "../../../api/ApiConsumer";
import { API_ROUTES } from "../../../routes/api";
import Select from "react-select";

export function Pricing({ details = {}, setDetails }) {
    const [addOns, setAddOns] = useState([])

    function fetch_addons() {
        ApiConsumer.get(API_ROUTES.INVENTORY.INVENTORY.ADDONS_LIST)
            .then(res => {
                let temp = []
                res.data.filter(x => x.active).map(supplier => {
                    temp.push({ label: supplier.name, value: supplier.id })
                })
                setAddOns(temp)
            })
            .catch(err => console.error(err))
    }

    useEffect(() => {
        fetch_addons()
    }, [])

    return <>
        <form className="space-y-6 sm:px-6 lg:col-span-9 lg:px-0 border-2 border-gray-300 rounded mt-2"
            method="POST">
            <div className="shadow sm:rounded-md">
                <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
                    <div>
                        <h3 className="text-lg font-medium leading-6 text-gray-900">Pricing</h3>
                    </div>

                    <div className="grid grid-cols-12 gap-6">
                        <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                            <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                                Price
                            </label>
                            <div className="mt-1 flex rounded-md shadow-sm">
                                <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-sm text-gray-500">
                                    R
                                </span>
                                <input
                                    type="number"
                                    className="block w-full flex-1 rounded-none rounded-r-md border-gray-300 focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm"
                                    placeholder="0"
                                    onChange={event => {
                                        setDetails((prevState) => ({
                                            ...prevState,
                                            [`price`]: event.target.value,
                                        }))
                                    }}
                                    value={details?.price}
                                />
                            </div>
                        </div>
                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                            <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700">
                                Includes VAT
                            </label>
                            <Switch
                                checked={details?.vat ?? false}
                                onChange={checked => {
                                    setDetails((prevState) => ({
                                        ...prevState,
                                        [`vat`]: checked,
                                    }))
                                }}
                                className={`${details?.vat ? 'bg-emerald-500' : 'bg-rose-500'
                                    } relative inline-flex h-6 w-11 items-center rounded-full mt-2`}
                            >
                                <span className="sr-only">Enable visibility</span>
                                <span
                                    className={`${details?.vat ? 'translate-x-6' : 'translate-x-1'
                                        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                />
                            </Switch>
                        </div>
                        <div className="col-span-6 sm:col-span-3 lg:col-span-4">
                            <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700">
                                Add On Costs
                            </label>
                            <Select
                                id="stations"
                                name="stations"
                                placeholder="Add On Costs"
                                isMulti={true}
                                options={addOns}
                                classNamePrefix="multi-select"
                                className="block w-full rounded border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                onChange={(event) => {
                                    setDetails((prevState) => ({
                                        ...prevState,
                                        [`brand_id`]: event.value
                                    }))
                                }}
                                value={addOns[addOns.findIndex((option) => option.value === addOns?.value,)]}
                            />
                        </div>
                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                            <label htmlFor="region" className="block text-sm font-medium text-gray-700">
                                Discount amount
                            </label>
                            <div className="mt-1 flex rounded-md shadow-sm">
                                <input
                                    type="number"
                                    className="block w-full flex-1 rounded-none rounded-l-md border-gray-300 focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm"
                                    placeholder="0"
                                    onChange={event => {
                                        setDetails((prevState) => ({
                                            ...prevState,
                                            [`discount_amount`]: event.target.value,
                                        }))
                                    }}
                                    value={details?.discount_amount}
                                />
                                <span
                                    className="inline-flex items-center rounded-r-md border border-l-0 border-gray-300 bg-gray-50 px-3 text-sm text-gray-500">
                                    %
                                </span>
                            </div>
                        </div>
                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                            <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700">
                                Is Discounted
                            </label>
                            <Switch
                                checked={details?.discount ?? false}
                                onChange={checked => {
                                    setDetails((prevState) => ({
                                        ...prevState,
                                        [`discount`]: checked,
                                    }))
                                }}
                                className={`${details?.discount ? 'bg-emerald-500' : 'bg-rose-500'
                                    } relative inline-flex h-6 w-11 items-center rounded-full mt-2`}
                            >
                                <span className="sr-only">Enable visibility</span>
                                <span
                                    className={`${details?.discount ? 'translate-x-6' : 'translate-x-1'
                                        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                />
                            </Switch>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </>
}
