import {NavLink} from "react-router-dom";

export function InventoryCard({inventory}) {
    return <>
        <NavLink to={`/inventory/${inventory?.id}`}
                 className="c-card block bg-white shadow-md hover:shadow-xl rounded overflow-hidden border-2 border-gray-200 duration-200 hover:scale-105">
            <div className="relative pb-48 overflow-hidden">
                <img className="absolute inset-0 h-full w-full object-cover"
                     src={inventory?.thumbnail}
                     alt=""/>
            </div>
            <div className="p-4">
                    <span
                        className="inline-block px-2 py-1 leading-none bg-emerald-200 text-emerald-800 rounded font-semibold uppercase tracking-wide text-xs">{inventory?.service ? 'Service' : 'Product'}</span>
                <h2 className="mt-2 mb-2  font-bold">{inventory?.name}</h2>
                <div className="mt-3 flex items-center">
                    <span className="text-sm font-semibold">R</span>&nbsp;<span
                    className="font-bold text-xl">{inventory?.price}</span>&nbsp;<span
                    className="text-sm font-semibold"></span>
                </div>
            </div>
            <div className="p-4 border-t border-b text-xs text-gray-700">
          <span className="flex items-center mb-1">
            <i className="far fa-clock fa-fw mr-2 text-gray-900"></i> {inventory?.category?.name}
          </span>
                <span className="flex items-center">
                    <span
                        className="inline-flex items-center rounded-md bg-emerald-100 px-2.5 py-0.5 text-sm font-medium text-emerald-800">
                        {inventory?.supplier?.name}
                    </span>
                    <span
                        className="inline-flex items-center rounded-md bg-gray-100 px-2.5 py-0.5 text-sm font-medium text-gray-800">
                        {inventory?.brand?.name}
                    </span>
          </span>
            </div>
        </NavLink>
    </>
}
