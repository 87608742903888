import React from "react";
import {Link, NavLink} from "react-router-dom";
import {HomeIcon} from "@heroicons/react/outline";
import {SIDEBAR_ROUTES} from "../../routes/sidebar";
import {APP_ROUTES} from "../../routes/app";
import {useAuthentication} from "../../hooks/AuthenticationHook";
import LOGO from 'assets/white.png'

export default function Sidebar(){
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    let {currentUser} = useAuthentication()

    function is_active(url) {
        return true
    }

    return <>
        <div className="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
            <div className="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
                <div className="flex min-h-0 flex-1 flex-col bg-gray-900">
                    <div className="flex h-16 flex-shrink-0 items-center bg-emerald-900 px-4">
                        <img
                            className="h-8 w-auto mx-auto"
                            src={LOGO}
                            alt="Stoko Admin"
                        />
                    </div>
                    <nav className="mt-5 flex-1 space-y-1 px-2">
                        <NavLink to='/'
                                 exact={true}
                                 activeClassName={'bg-emerald-900'}
                                 className={'text-white hover:bg-gray-600 hover:bg-opacity-75 group flex items-center px-2 py-2 text-sm font-medium rounded-md'}>
                            <HomeIcon className={'text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0 h-6 w-6'}/>
                            Dashboard
                        </NavLink>
                        {
                            Object.keys(SIDEBAR_ROUTES).map((sidebar, i) => {
                                return <div key={i}>
                                    <div className='mt-1 pt-2'>
                                        <div className='space-y-1'>
                                                    <span className='text-emerald-500 font-medium text-xs'>
                                                      {SIDEBAR_ROUTES[sidebar]}
                                                    </span>
                                        </div>
                                    </div>
                                    {APP_ROUTES.filter(x => x.sidebar && x.sidebar_route === SIDEBAR_ROUTES[sidebar]).map((route, x) => {
                                        return <NavLink to={route.link} key={x}
                                                        exact={true}
                                                        activeClassName={'bg-emerald-900 border-blue-500 font-bold text-emerald-500 text-white'}
                                                        className={'mt-2 text-white hover:bg-gray-600 hover:bg-opacity-75 group flex items-center px-2 py-2 text-sm font-medium rounded-md'}>
                                            <route.icon
                                                className={classNames(
                                                    (is_active(route.link)) ? 'text-white' : 'text-gray-200 group-hover:text-gray-500',
                                                    'mr-3 flex-shrink-0 h-6 w-6'
                                                )}
                                                aria-hidden='true'
                                            />{route.name}</NavLink>
                                    })
                                    }
                                </div>
                            })
                        }


                    </nav>
                </div>
                <div className="flex flex-shrink-0 bg-gray-700 p-4">
                    <a href="#" className="group block w-full flex-shrink-0">
                        <div className="flex items-center">
                            <div>
                                <img
                                    className="inline-block h-9 w-9 rounded-full"
                                    src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                    alt=""
                                />
                            </div>
                            <div className="ml-3">
                                <p className="text-sm font-medium text-white">{`${currentUser()?.name} ${currentUser()?.surname}`}</p>
                                <Link to={'/account'} className="text-xs font-medium text-indigo-200 group-hover:text-white">View
                                    profile</Link>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </>
}
