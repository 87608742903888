import {GoogleMap, MarkerF, useLoadScript} from "@react-google-maps/api";
import {useMemo, useRef} from "react";
import {mapStyle} from "../../assets/styles/map-styles";
import AGENT_ICON from "../../assets/icons/agent-marker.png"
import CUSTOMER_ICON from "../../assets/icons/you-marker.png"

export function OrderDetailMap({order}) {

    const inputRef = useRef();

    const {isLoaded} = useLoadScript({
        googleMapsApiKey: "AIzaSyCzGMzh6rNBPkZZERfkjS9vnxRi8dvZxCg",
        libraries: ['places']
    })

    const mapCenter = useMemo(
        () => ({lat: parseFloat(order?.latitude), lng: parseFloat(order?.longitude)}),
        []
    );

    if (!isLoaded) {
        return <div className='h-screen'>
            <p>Loading...</p>
        </div>
    }

    return <>
        <div className="h-96 border-b border-r border-l border-gray-200 bg-white shadow-sm sm:rounded-b-lg sm:border-r sm:border-l mb-28 pb-10">
            <GoogleMap
                zoom={6.01}
                options={{
                    disableDefaultUI: true,
                    zoomControl: false,
                    styles: mapStyle,
                }}
                center={mapCenter}
                // mapTypeId={google?.maps.MapTypeId.ROADMAP}
                mapContainerStyle={{width: '100%', height: '150%'}}
            >
                <MarkerF
                    key={order?.id}
                    position={{lat: parseInt(order?.latitude), lng: parseInt(order?.longitude)}}
                    //onClick={(event) => handleMarkerClick(event, marker)}
                    onLoad={() => console.log('Marker Loaded')}
                    icon={CUSTOMER_ICON}
                />
            </GoogleMap>
        </div>
    </>
}
