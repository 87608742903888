import React, {useContext} from 'react'
import Sidebar from "../components/sidebar";
import PageTitle from "../components/page-title";
import Breadcrumbs from "../components/breadcrumbs";
import Loader from "../components/loader";
import {LoaderContext} from "../contexts/loader-context";

export default function PageLayout({component = null}) {
    let {loading} = useContext(LoaderContext)
    return <>
        <div>
            <Sidebar/>
            <div className="flex flex-1 flex-col md:pl-64">
                <main className="flex-1">
                    <div className="py-0">
                        <PageTitle/>
                        <Breadcrumbs/>
                        {
                            loading ?
                                <Loader full_height={true}/> :
                                <div className="mx-auto mt-4 px-4 sm:px-6 md:px-8">
                                    {(component)}
                                </div>
                        }
                    </div>
                </main>
            </div>
        </div>
    </>
}
