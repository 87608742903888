import React, {useState} from 'react';

const PageTitleContext = React.createContext({
    title: '',
    setTitle: () => {
    }
});

const PageTitleProvider = ({children}) => {
    const [title, setTitle] = useState('')

    return <PageTitleContext.Provider value={{title, setTitle}}>
        {children}
    </PageTitleContext.Provider>
};

export {PageTitleProvider, PageTitleContext}
