import {UserCircleIcon} from "@heroicons/react/outline";
import {useState} from "react";
import UserModal from "../modals/user-modal";

export function UserCard({user, refresh=()=>{}}){
    const [open,setOpen] = useState(false)
    return <>
            <UserModal user={user} setOpen={setOpen} open={open} refresh={refresh} />
            <div onClick={()=>setOpen(true)} className={`text-white w-full max-w-md flex flex-col rounded shadow-lg p-4 cursor-pointer border-2 ${open ? 'border-emerald-500' : 'border-gray-300'} duration-200 hover:scale-105`}>
                <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-4">
                        <div className="text-md font-bold text-gray-500">{`${user?.first_name} ${user?.last_name}`}</div>
                    </div>
                    <div className="flex items-center space-x-4">
                        <div className="cursor-pointer">
                            {
                                user?.is_active ? <UserCircleIcon className='text-emerald-500 h-6'/>
                                    : <UserCircleIcon className='text-rose-500 h-6'/>
                            }
                        </div>
                    </div>
                </div>
                <div className="text-sm text-gray-400">{`${user?.email}`}</div>
                <div className="text-sm text-gray-400">{`${user?.cellphone}`}</div>
                <div className="mt-4 text-gray-500 font-bold text-sm">
                    #{user?.type}
                </div>
            </div>
    </>
}
