import {Link, useHistory} from "react-router-dom";
import {useContext, useState} from "react";
import axios from "axios";
import * as Sentry from '@sentry/react'
import {API_ROUTES} from "../routes/api";
import {CogIcon} from "@heroicons/react/outline";
import {AuthenticationContext} from "../contexts/AuthenticationContext";
import {useAuthentication} from "../hooks/AuthenticationHook";
import LOGO from 'assets/green.png'

export default function Login() {
    const [details, setDetails] = useState({})
    const [loading, setLoading] = useState(false)
    let {setUser} = useContext(AuthenticationContext)
    let {setAuthentication} = useAuthentication()

    let history = useHistory()

    function login() {
        setLoading(true)
        axios
            .post(API_ROUTES.AUTHENTICATION.LOGIN, details)
            .then((res) => {
                setUser(res.data)
                setAuthentication(res.data)
                history.push('/')
                //setNotification({show: true, title:'Welcome', message:`Welcome ${res.data?.name}`, type: 'success'})
            })
            .catch((err) => {
                //setNotification({show: true, title:'Login error', message:'Could not log you in, please try again later.', type: 'error'})
                Sentry.captureException(err)
            })
            .finally(() => setLoading(false))
    }

    return <>
        <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <img
                    className="mx-auto h-12 w-auto"
                    src={LOGO}
                    alt="WiEnergi"
                />
                <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">Sign in</h2>
            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 shadow-2xl">
                    <form className="space-y-6" method="POST" onSubmit={(event) => {
                        event.preventDefault()
                        login()
                    }}>
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                Email address
                            </label>
                            <div className="mt-1">
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    required
                                    onChange={(event) => {
                                        setDetails((prevState) => ({
                                            ...prevState,
                                            [`username`]: event.target.value,
                                        }))
                                    }}
                                    value={details?.username}
                                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                Password
                            </label>
                            <div className="mt-1">
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    autoComplete="current-password"
                                    required
                                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                    onChange={(event) => {
                                        setDetails((prevState) => ({
                                            ...prevState,
                                            [`password`]: event.target.value,
                                        }))
                                    }}
                                    value={details.password}
                                />
                            </div>
                        </div>

                        <div className="flex items-center justify-between text-center">

                            <div className="text-sm">
                                <Link to="/forgot-password"
                                      className="text-center font-medium text-emerald-500 hover:text-orange-500">
                                    Forgot your password?
                                </Link>
                            </div>
                        </div>

                        <div>
                            <button
                                type="submit"
                                className="flex w-full justify-center rounded-md border border-transparent bg-emerald-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-emerald-900 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
                            >
                                {
                                    loading ? <CogIcon
                                            className="animate-spin h-6 w-6 content-center text-center mx-auto"/> :
                                        <>Sign in</>
                                }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>
}
