import {useContext, useEffect, useState} from "react";
import {PageTitleContext} from "../contexts/page-title-context";
import {CrumbsContext} from "../contexts/crumbs-context";
import {ApiConsumer} from "../api/ApiConsumer";
import {API_ROUTES} from "../routes/api";
import {PlusCircleIcon} from "@heroicons/react/outline";
import {UserCard} from "../components/cards/user";
import UserModal from "../components/modals/user-modal";
import Loader from "../components/loader";

export default function Users() {
    const [users, setUsers] = useState([])
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    let {setTitle} = useContext(PageTitleContext)
    let {setCrumbs} = useContext(CrumbsContext)

    function fetch() {
        ApiConsumer.get(API_ROUTES.ADMINISTRATION.USERS.LIST)
            .then(res => setUsers(res.data))
            .catch(err => console.error(err))
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        setTitle('Users')
        setCrumbs([{'name': 'Administration', 'link': '#'}, {'name': 'Users', 'link': '/admin/users'}])

        fetch()
    }, [])

    return <>
        <div className="md:flex md:items-center md:justify-between mb-6">
            <div className="min-w-0 flex-1"/>
            <div className="mt-4 flex md:mt-0 md:ml-4">
                <PlusCircleIcon
                    onClick={() => setOpen(true)}
                    className="h-12 cursor-pointer text-emerald-500 px-4 py-2 text-sm font-medium hover:text-emerald-700"/>
            </div>
        </div>
        <UserModal setOpen={setOpen} open={open} refresh={fetch} />
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-5 mb-12">
            {
                loading ? <div className='col-span-5'>
                        <Loader full_height={true}/>
                    </div> :
                users.map((user, i) => {
                    return <UserCard key={i} user={user} refresh={fetch}/>
                })
            }
        </div>
    </>
}
