import {Menu, Transition} from "@headlessui/react";
import {ChevronDownIcon, UserCircleIcon} from "@heroicons/react/outline";
import React, {Fragment, useContext} from "react";
import {NavLink} from "react-router-dom";
import {useAuthentication} from "../../hooks/AuthenticationHook";
import {PageTitleContext} from "../../contexts/page-title-context";

export default function PageTitle() {
    let {currentUser} = useAuthentication()
    let {title} = useContext(PageTitleContext)

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    return <>
        <div className="mx-auto">
            <div
                className="relative z-10 flex-shrink-0 flex h-16 bg-white border-b border-gray-200 lg:border-none shadow">

                {/* Search bar */}
                <div className="flex-1 flex justify-between sm:px-6 lg:max-w-full lg:mx-auto lg:px-8">
                    <div className="flex-1 flex">
                        <div className="flex-1 min-w-0 mt-3">
                            <h2 className="text-2xl leading-7 text-gray-600 sm:text-3xl sm:truncate">{title}</h2>
                        </div>
                    </div>
                    <div className="ml-4 flex items-center md:ml-6">
                        {/* Profile dropdown */}
                        <Menu as="div" className="ml-0 relative">
                            <div>
                                <Menu.Button
                                    className="max-w-full bg-white rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 lg:p-2 lg:rounded-md lg:hover:bg-gray-50">
                                    <UserCircleIcon
                                        className="h-7 w-7 rounded-full"
                                        alt=""
                                    />
                                    <span className="hidden ml-3 text-gray-700 text-sm font-medium lg:block">
                                                <span className="sr-only">Open user menu for </span>
                                        {`${currentUser()?.name} ${currentUser()?.surname}`}
                                            </span>
                                    <ChevronDownIcon
                                        className="hidden flex-shrink-0 ml-1 h-5 w-5 text-gray-400 lg:block"
                                        aria-hidden="true"
                                    />
                                </Menu.Button>
                            </div>
                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items
                                    className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <Menu.Item>
                                        {({active}) => (
                                            <NavLink
                                                to={'/account'}
                                                className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                            >
                                                Your Profile
                                            </NavLink>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({active}) => (
                                            <div
                                                onClick={() => {
                                                    //logout()
                                                    window.location.reload()
                                                    //history.push('/login')
                                                    window.location.reload()
                                                }}
                                                className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                            >
                                                Logout
                                            </div>
                                        )}
                                    </Menu.Item>
                                </Menu.Items>
                            </Transition>
                        </Menu>
                    </div>
                </div>
            </div>
        </div>
    </>
}
