import {useContext, useEffect, useState} from "react";
import {PageTitleContext} from "../contexts/page-title-context";
import {CrumbsContext} from "../contexts/crumbs-context";
import {ArrowLeftIcon} from "@heroicons/react/outline";
import {GeneralInfo} from "../components/forms/inventory/general-info";
import {Organize} from "../components/forms/inventory/organize";
import {Shipping} from "../components/forms/inventory/shipping";
import {Pricing} from "../components/forms/inventory/pricing";
import {Availability} from "../components/forms/inventory/availability";
import {NavLink, useHistory, useParams} from "react-router-dom";
import {ApiConsumer} from "../api/ApiConsumer";
import {API_ROUTES} from "../routes/api";
import {LoaderContext} from "../contexts/loader-context";
import {useUtils} from "../hooks/utils-hook";

export default function InventoryDetail() {
    const [details, setDetails] = useState({})
    const [name, setName] = useState(null)
    const [open, setOpen] = useState(false)
    let {setLoading} = useContext(LoaderContext)
    let {setTitle} = useContext(PageTitleContext)
    let {setCrumbs} = useContext(CrumbsContext)
    let {BuildFormData} = useUtils()
    let history = useHistory();

    let {id} = useParams();

    function load_info() {
        ApiConsumer.get(API_ROUTES.INVENTORY.INVENTORY.DETAIL(id))
            .then(res => {
                setDetails(res?.data)
                setCrumbs([{'name': 'Inventory', 'link': '/inventory'}, {
                    'name': res?.data?.name ?? 'New Product',
                    'link': `/inventory/${id}`
                }])
            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        load_info()
        setTitle('Inventory')
        setCrumbs([{'name': 'Inventory', 'link': '/inventory'}, {
            'name': name ?? 'New Product',
            'link': `/inventory/${id}`
        }])

        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });

    }, [])

    function save() {
        setLoading(true)
        if (details?.id) {
            ApiConsumer.put(API_ROUTES.INVENTORY.INVENTORY.DETAIL(details?.id), BuildFormData(details))
                .then(() => {
                    history.push("/inventory");
                })
                .catch(err => {
                    console.error(err)
                })
                .finally(() => setLoading(false))
        } else
            ApiConsumer.post(API_ROUTES.INVENTORY.INVENTORY.LIST, BuildFormData(details))
                .then(() => {
                    history.push("/inventory");
                })
                .catch(err => {
                    console.error(err)
                })
                .finally(() => setLoading(false))
    }

    useEffect(() => {
        console.log(details)
    }, [details])

    return <>
        <div className="md:flex md:items-center md:justify-between mb-6">
            <div className="min-w-0 flex-1">
                <NavLink to={'/inventory'}>
                    <ArrowLeftIcon
                        onClick={() => setOpen(true)}
                        className="h-10 cursor-pointer text-gray-500 py-2 text-sm font-medium hover:text-emerald-700"/>
                </NavLink>
            </div>
            <div className="mt-4 flex md:mt-0 md:ml-4" onClick={save}>
                <svg className="h-12 cursor-pointer text-gray-500 py-2 text-sm font-medium hover:text-emerald-700"
                     xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path
                        d="M5 21h14a2 2 0 0 0 2-2V8a1 1 0 0 0-.29-.71l-4-4A1 1 0 0 0 16 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2zm10-2H9v-5h6zM13 7h-2V5h2zM5 5h2v4h8V5h.59L19 8.41V19h-2v-5a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v5H5z"></path>
                </svg>
            </div>

        </div>

        <div className="grid grid-cols-1 gap-4 md:grid-cols-1 lg:grid-cols-12">
            <div className='col-span-8'>
                <GeneralInfo details={details} setDetails={setDetails}/>
                <Pricing details={details} setDetails={setDetails}/>
            </div>


            <div className='col-span-4'>
                <Organize details={details} setDetails={setDetails}/>
                <Shipping details={details} setDetails={setDetails}/>
                <Availability details={details} setDetails={setDetails}/>
            </div>
        </div>
    </>
}
