import {useEffect, useState} from "react";
import {ApiConsumer} from "../../api/ApiConsumer";
import {API_ROUTES} from "../../routes/api";

export function InventoryStats() {
    const [data, setData] = useState([])

    function load_brands() {
        ApiConsumer.get(API_ROUTES.INVENTORY.BRANDS.LIST)
            .then(res => {
                let temp = Array.from(data);
                temp.push({
                    name: 'Brands',
                    stat: res.data?.length,
                    wording: `total , ${res.data?.filter(x => x.active).length} active.`
                })

                setData(temp)
            })
    }

    function load_suppliers() {
        ApiConsumer.get(API_ROUTES.INVENTORY.SUPPLIERS.LIST)
            .then(res => {
                let temp = Array.from(data);
                temp.push({
                    name: 'Suppliers',
                    stat: res.data?.length,
                    wording: `total , ${res.data?.filter(x => x.active).length} active.`
                })
                setData(temp)
            })
    }

    useEffect(() => {
        load_brands()
        load_suppliers()
    }, [])
    return <>
        <div className={'mb-5 shadow-lg bg-white'}>
            <dl className="mt-1 grid grid-cols-1 bg-white overflow-hidden shadow divide-y divide-gray-200 md:grid-cols-5 md:divide-y-0 md:divide-x">
                {data.map((item) => (
                    <div key={item.name} className="px-4 py-5 sm:p-6">
                        <dt className="text-base font-normal text-gray-700">{item.name}</dt>
                        <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                            <div className="flex items-baseline text-2xl font-semibold text-emerald-600">
                                {item.stat}
                                <span className="ml-2 text-sm font-medium text-gray-500">{item?.wording}</span>
                            </div>
                        </dd>
                    </div>
                ))}
            </dl>
        </div>
    </>
}
