import {useContext, useEffect, useState} from "react";
import {PageTitleContext} from "../contexts/page-title-context";
import {CrumbsContext} from "../contexts/crumbs-context";
import {ApiConsumer} from "../api/ApiConsumer";
import {API_ROUTES} from "../routes/api";
import {PlusCircleIcon} from "@heroicons/react/outline";
import {OrderCard} from "../components/cards/order";
import Loader from "../components/loader";

export default function Orders() {
    const [orders, setOrders] = useState([])
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    let {setTitle} = useContext(PageTitleContext)
    let {setCrumbs} = useContext(CrumbsContext)

    function load_data() {
        ApiConsumer.get(API_ROUTES.STORE.ORDERS.LIST)
            .then(res => {
                setOrders(res?.data)
                console.log(res?.data.slice(0, 10))
            })
            .catch(err => console.error(err))
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        setTitle('Orders')
        setCrumbs([{'name': 'Orders', 'link': '/orders'}])
        load_data()
    }, [])
    return <>
        <div className="md:flex md:items-center md:justify-between mb-6">
            <div className="min-w-0 flex-1"/>
            <div className="mt-4 flex md:mt-0 md:ml-4">
                <PlusCircleIcon
                    onClick={() => setOpen(true)}
                    className="h-12 cursor-pointer text-emerald-500 px-4 py-2 text-sm font-medium hover:text-emerald-700"/>
            </div>
        </div>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
            {
                loading ? <div className='col-span-5'>
                        <Loader full_height={true}/>
                    </div> :
                    orders.map((order, i) => {
                        return <OrderCard key={i} order={order}/>
                    })
            }
        </div>
    </>
}
