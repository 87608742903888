import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {useState} from "react";
import RichTextEditor from "react-rte";
import {useUtils} from "../../../hooks/utils-hook";
export function GeneralInfo({details={}, setDetails}) {
    let {TextEditorFormatter} = useUtils()
    const [des, setDes] = useState(RichTextEditor.createValueFromString(details?.description, 'html'))
    return <>
        <form className="space-y-6 sm:px-6 lg:col-span-9 lg:px-0 border-2 border-gray-300 rounded"
              method="POST">
            <div className="shadow sm:overflow-hidden sm:rounded-md">
                <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
                    <div>
                        <h3 className="text-lg font-medium leading-6 text-gray-900">General Information</h3>
                    </div>

                    <div className="grid grid-cols-6 gap-6">
                        <div className="col-span-6 sm:col-span-6">
                            <label htmlFor="company-website"
                                   className="block text-sm font-medium text-gray-700">
                                Name
                            </label>
                            <div className="mt-1 flex rounded-md shadow-sm">
                                <input
                                    type="text"
                                    required
                                    className="w-full flex-grow rounded border-gray-300 focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm"
                                    onChange={event => {
                                        setDetails((prevState) => ({
                                            ...prevState,
                                            [`name`]: event.target.value,
                                        }))
                                    }}
                                    value={details?.name}
                                />
                            </div>
                        </div>

                        <div className="col-span-6">
                            <label htmlFor="about" className="block text-sm font-medium text-gray-700">
                                Description
                            </label>
                            <div className="mt-1">
                                <RichTextEditor
                                    className="new-post-editor"
                                    value={des}
                                    onChange={text => {
                                        setDes(text)
                                        setDetails(prevState => ({
                                            ...prevState,
                                            ['description']: text.toString("html")
                                        }))
                                    }}
                                />
                    {/*<textarea*/}
                    {/*    id="about"*/}
                    {/*    name="about"*/}
                    {/*    rows={3}*/}
                    {/*    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm"*/}
                    {/*    placeholder="Description"*/}
                    {/*    defaultValue={''}*/}
                    {/*    onChange={event => {*/}
                    {/*        setDetails((prevState) => ({*/}
                    {/*            ...prevState,*/}
                    {/*            [`description`]: event.target.value,*/}
                    {/*        }))*/}
                    {/*    }}*/}
                    {/*    value={details?.description}*/}
                    {/*/>*/}
                            </div>
                        </div>


                        <div className="col-span-6">
                            <label className="block text-sm font-medium text-gray-700">Thumbnail</label>
                            <div
                                className="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                                <div className="space-y-1 text-center">
                                    {
                                        details?.thumbnail ? <img className='mx-auto h-20 w-32'
                                                                     src={details?.thumbnail}
                                                                     alt={'Thumbnail'}/> : <svg
                                            className="mx-auto h-12 w-12 text-gray-400"
                                            stroke="currentColor"
                                            fill="none"
                                            viewBox="0 0 48 48"
                                            aria-hidden="true"
                                        >
                                            <path
                                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                strokeWidth={2}
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    }
                                    <div className="flex text-sm text-gray-600">
                                        <label
                                            htmlFor="file-upload"
                                            className="relative cursor-pointer rounded-md bg-white font-medium text-emerald-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-emerald-500 focus-within:ring-offset-2 hover:text-emerald-500"
                                        >
                                            <span>Upload a file</span>
                                            <input id="file-upload"
                                                   name="file-upload"
                                                   type="file"
                                                   className="sr-only"
                                                   onChange={event => {
                                                       setDetails((prevState) => ({
                                                           ...prevState,
                                                           [`thumbnail`]: event.target.files[0],
                                                       }))
                                                   }}
                                            />
                                        </label>
                                        <p className="pl-1">or drag and drop</p>
                                    </div>
                                    <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
                                </div>
                            </div>
                        </div>
                        {/*<div className="col-span-6">*/}
                        {/*    <label className="block text-sm font-medium text-gray-700">Image</label>*/}
                        {/*    <div*/}
                        {/*        className="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">*/}
                        {/*        <div className="space-y-1 text-center">*/}
                        {/*            {*/}
                        {/*                details?.feature_image ? <img className='mx-auto h-20 w-32'*/}
                        {/*                                             src={details?.feature_image}*/}
                        {/*                                             alt={'Image'}/> : <svg*/}
                        {/*                    className="mx-auto h-12 w-12 text-gray-400"*/}
                        {/*                    stroke="currentColor"*/}
                        {/*                    fill="none"*/}
                        {/*                    viewBox="0 0 48 48"*/}
                        {/*                    aria-hidden="true"*/}
                        {/*                >*/}
                        {/*                    <path*/}
                        {/*                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"*/}
                        {/*                        strokeWidth={2}*/}
                        {/*                        strokeLinecap="round"*/}
                        {/*                        strokeLinejoin="round"*/}
                        {/*                    />*/}
                        {/*                </svg>*/}
                        {/*            }*/}
                        {/*            <div className="flex text-sm text-gray-600">*/}
                        {/*                <label*/}
                        {/*                    htmlFor="file-upload"*/}
                        {/*                    className="relative cursor-pointer rounded-md bg-white font-medium text-emerald-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-emerald-500 focus-within:ring-offset-2 hover:text-emerald-500"*/}
                        {/*                >*/}
                        {/*                    <span>Upload a file</span>*/}
                        {/*                    <input id="file-upload-1" name="file-upload-1" type="file"*/}
                        {/*                           className="sr-only"*/}
                        {/*                           onChange={event => {*/}
                        {/*                               setDetails((prevState) => ({*/}
                        {/*                                   ...prevState,*/}
                        {/*                                   [`feature_image`]: event.target.files[0],*/}
                        {/*                               }))*/}
                        {/*                           }}*/}
                        {/*                    />*/}
                        {/*                </label>*/}
                        {/*                <p className="pl-1">or drag and drop</p>*/}
                        {/*            </div>*/}
                        {/*            <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </form>
    </>
}
