import {useContext, useEffect, useState} from "react";
import {PageTitleContext} from "../contexts/page-title-context";
import {CrumbsContext} from "../contexts/crumbs-context";
import {ApiConsumer} from "../api/ApiConsumer";
import {API_ROUTES} from "../routes/api";
import Loader from "../components/loader";
import {OrdersMap} from "../components/maps/orders-map";

export default function OrdersMapPage() {
    const [orders, setOrders] = useState([])
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    let {setTitle} = useContext(PageTitleContext)
    let {setCrumbs} = useContext(CrumbsContext)

    function load_data() {
        ApiConsumer.get(API_ROUTES.STORE.ORDERS.LIST)
            .then(res => {
                setOrders(res?.data)
            })
            .catch(err => console.error(err))
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        setTitle('Orders Map')
        setCrumbs([{'name': 'Orders', 'link': '/orders'}, {'name': 'Map', 'link': '/orders-map'}])
        load_data()
    }, [])
    return <>
        {
            loading ? <Loader full_height={true}/> : <OrdersMap orders={orders}/>
        }
    </>
}
