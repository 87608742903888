import {Fragment, useEffect, useState} from "react";
import {ApiConsumer} from "../../../api/ApiConsumer";
import {API_ROUTES} from "../../../routes/api";
import Select from "react-select";
import {CheckIcon, ChevronUpIcon, ChevronDownIcon} from "@heroicons/react/outline";
import {Listbox, Transition} from "@headlessui/react";


export function Organize({details={}, setDetails}){
    const [suppliers, setSuppliers] = useState([])
    const [categories, setCategories] = useState([])
    const [brands, setBrands] = useState([])
    function fetch_suppliers() {
        ApiConsumer.get(API_ROUTES.INVENTORY.SUPPLIERS.LIST)
            .then(res => {
                let temp = []
                res.data.map(supplier => {
                    temp.push({label: supplier.name, value: supplier.id})
                })
                setSuppliers(temp)
            })
            .catch(err => console.error(err))
    }

    function fetch_brands() {
        ApiConsumer.get(API_ROUTES.INVENTORY.BRANDS.LIST)
            .then(res => {
                let temp = []
                res.data.map(supplier => {
                    temp.push({label: supplier.name, value: supplier.id})
                })
                setBrands(temp)
            })
            .catch(err => console.error(err))
    }

    function fetch_categories() {
        ApiConsumer.get(API_ROUTES.ADMINISTRATION.CATEGORIES.LIST)
            .then(res => {
                let temp = []
                res.data.map(supplier => {
                    temp.push({label: supplier.name, value: supplier.id})
                })
                setCategories(temp)
            })
            .catch(err => console.error(err))
    }

    useEffect(() => {
        fetch_suppliers()
        fetch_brands()
        fetch_categories()
    }, [])

    return <>
        <form className="space-y-6 sm:px-6 lg:col-span-9 lg:px-0 border-2 border-gray-300 rounded"
              method="POST">
            <div className="shadow sm:rounded-md">
                <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
                    <div>
                        <h3 className="text-lg font-medium leading-6 text-gray-900">Organize</h3>
                    </div>

                    <div className="grid grid-cols-6 gap-6">
                        <div className="col-span-6 sm:col-span-6">
                            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                Supplier
                            </label>
                            <Select
                                id="stations"
                                name="stations"
                                placeholder="Supplier"
                                isMulti={false}
                                options={suppliers}
                                classNamePrefix="multi-select"
                                className="block w-full rounded border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm overflow-visible"

                                onChange={(event) => {
                                    setDetails((prevState) => ({
                                        ...prevState,
                                        [`supplier_id`]: event.value
                                    }))
                                }}
                                value={suppliers[suppliers.findIndex((option) => option.value === details?.supplier_id,)]}
                            />
                        </div>

                        <div className="col-span-6 sm:col-span-6">
                            <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                                Brand
                            </label>
                            <Select
                                id="stations"
                                name="stations"
                                placeholder="Brand"
                                isMulti={false}
                                options={brands}
                                classNamePrefix="multi-select"
                                className="block w-full rounded border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"

                                onChange={(event) => {
                                    setDetails((prevState) => ({
                                        ...prevState,
                                        [`brand_id`]: event.value
                                    }))
                                }}
                                value={brands[brands.findIndex((option) => option.value === details?.brand_id,)]}
                            />
                        </div>

                        <div className="col-span-6 sm:col-span-6">
                            <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                                Category
                            </label>
                            <Select
                                id="stations"
                                name="stations"
                                placeholder="Category"
                                isMulti={false}
                                options={categories}
                                classNamePrefix="multi-select"
                                className="w-full rounded border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm overflow-visible"

                                onChange={(event) => {
                                    setDetails((prevState) => ({
                                        ...prevState,
                                        [`category_id`]: event.value
                                    }))
                                }}
                                value={categories[categories.findIndex((option) => option.value === details?.category_id,)]}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </>
}
