import {useContext, useEffect, useState} from "react";
import {PageTitleContext} from "../contexts/page-title-context";
import {CrumbsContext} from "../contexts/crumbs-context";
import {ApiConsumer} from "../api/ApiConsumer";
import {API_ROUTES} from "../routes/api";
import {useParams} from "react-router-dom";
import {useUtils} from "../hooks/utils-hook";
import {OrderDetailMap} from "../components/maps/order-detail-map";
import Loader from "../components/loader";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function OrderDetail() {
    const [order, setOrder] = useState()
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    let {setTitle} = useContext(PageTitleContext)
    let {setCrumbs} = useContext(CrumbsContext)

    let {convertFullDateToStringNoTime, formatCurrency} = useUtils()

    let {id} = useParams();

    function load_data() {
        ApiConsumer.get(API_ROUTES.STORE.ORDERS.DETAIL(id))
            .then(res => {
                setOrder(res?.data);
                setCrumbs([{'name': 'Orders', 'link': '/orders'}, {
                    'name': res?.data?.name ?? 'Order Detail',
                    'link': `/order/${id}`
                }])
                setTitle(`Order #${order?.order_number.substring(0, 8).toUpperCase()}`)
                console.log('Order Detail: ', res?.data)
            })
            .catch(err => console.error(err))
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        load_data()
    }, [])

    return <>
        <div className="bg-gray-50">
            <div className="mx-auto">
                <div className="space-y-2 px-4 sm:flex sm:items-baseline sm:justify-between sm:space-y-0 sm:px-0">
                    <div className="flex sm:items-baseline sm:space-x-4">
                        <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">Order
                            #{order?.order_number.substring(0, 8).toUpperCase()}</h1>
                    </div>
                    <p className="text-sm text-gray-600">
                        Order placed{' '}
                        <time dateTime="2021-03-22" className="font-medium text-gray-900">
                            {convertFullDateToStringNoTime(new Date(order?.created))}
                        </time>
                    </p>

                </div>
            </div>

            <div className="mt-6">
                <h2 className="sr-only">Products purchased</h2>

                <div className="space-y-8">
                    <div
                        className=" border-t border-gray-200 bg-white shadow-sm sm:rounded-t-lg sm:border-r sm:border-l"
                    >
                        {order?.line_items?.map((product) => (
                            <div className="px-4 py-6 sm:px-6 lg:grid lg:grid-cols-12 lg:gap-x-8 lg:p-8">
                                <div className="sm:flex lg:col-span-7">
                                    <div className=" w-full  overflow-hidden rounded-lg sm:aspect-none sm:h-40 sm:w-40">
                                        <h3 className="text-base font-medium text-gray-900">
                                            {product?.inventory_item?.name}
                                        </h3>
                                        <img
                                            src={product.inventory_item?.thumbnail}
                                            alt={product.inventory_item?.name}
                                            className="h-full w-full object-cover object-center sm:h-full sm:w-full rounded shadow-2xl"
                                        />
                                        <h3 className="text-base font-medium text-gray-900">
                                            Qty : {product?.quantity}
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div className="border-t border-gray-200 px-4 py-6 sm:px-6 lg:p-8">
                            <h4 className="sr-only">Status</h4>
                            {/*<p className="text-sm font-medium text-gray-900">*/}
                            {/*    {product.status} on <time dateTime={product.datetime}>{product.date}</time>*/}
                            {/*</p>*/}
                            {/*<div className="mt-6" aria-hidden="true">*/}
                            {/*    <div className="overflow-hidden rounded-full bg-gray-200">*/}
                            {/*        <div*/}
                            {/*            className="h-2 rounded-full bg-indigo-600"*/}
                            {/*            style={{ width: `calc((${product.step} * 2 + 1) / 8 * 100%)` }}*/}
                            {/*        />*/}
                            {/*    </div>*/}
                            {/*    <div className="mt-6 hidden grid-cols-4 text-sm font-medium text-gray-600 sm:grid">*/}
                            {/*        <div className="text-indigo-600">Order placed</div>*/}
                            {/*        <div className={classNames(product.step > 0 ? 'text-indigo-600' : '', 'text-center')}>*/}
                            {/*            Processing*/}
                            {/*        </div>*/}
                            {/*        <div className={classNames(product.step > 1 ? 'text-indigo-600' : '', 'text-center')}>*/}
                            {/*            Shipped*/}
                            {/*        </div>*/}
                            {/*        <div className={classNames(product.step > 2 ? 'text-indigo-600' : '', 'text-right')}>*/}
                            {/*            Delivered*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>

                </div>
            </div>

            <div className="mt-0 border-b border-r border-l border-gray-200">
                <h2 className="sr-only">Billing Summary</h2>
                <div
                    className="bg-gray-100 px-4 py-2 sm:rounded-b-lg sm:px-6 lg:grid lg:grid-cols-12 lg:gap-x-8 lg:px-8 lg:py-3">
                    <dl className="grid grid-cols-2 gap-6 text-sm sm:grid-cols-2 md:gap-x-8 lg:col-span-7">
                        <div>
                            <dt className="font-medium text-gray-900">Billing address</dt>
                            <dd className="mt-1 text-gray-500">
                                <span className="block">{order?.address}</span>
                            </dd>
                        </div>

                    </dl>

                    <dl className="mt-8 divide-y divide-gray-200 text-sm lg:col-span-5 lg:mt-0">
                        <div className="flex items-center justify-end pt-4">
                            <dt className="font-bold text-gray-900 mr-4">Order total:</dt>
                            <dd className="font-bold text-emerald-600">{formatCurrency(order?.total_price)}</dd>
                        </div>
                    </dl>
                </div>
            </div>
        </div>
        {
            loading ? <div className='col-span-4'>
                    <Loader full_height={true}/>
                </div> :
                <OrderDetailMap order={order}/>
        }
    </>
}
