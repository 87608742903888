import { useEffect, useState } from "react";
import { ApiConsumer } from "../../../api/ApiConsumer";
import { API_ROUTES } from "../../../routes/api";
import Select from "react-select";
import { Switch } from "@headlessui/react";
import MultipleDatePicker from "react-multiple-datepicker";

export function Availability({ details = {}, setDetails }) {
    const handleDates = (dates) => {
        setDetails((prevState) => ({
            ...prevState,
            [`training_dates`]: dates,
        }));
    }

    return <>
        <form className="space-y-6 sm:px-6 lg:col-span-9 lg:px-0 border-2 border-gray-300 rounded mt-2"
            method="POST">
            <div className="shadow sm:overflow-hidden sm:rounded-md">
                <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
                    <div>
                        <h3 className="text-lg font-medium leading-6 text-gray-900">Availability</h3>
                    </div>

                    <div className="grid grid-cols-6 gap-6">
                        <div className="col-span-3 sm:col-span-3 lg:col-span-2">
                            <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                                Stock
                            </label>
                            <input
                                type="number"
                                className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                onChange={event => {
                                    setDetails((prevState) => ({
                                        ...prevState,
                                        [`stock`]: event.target.value,
                                    }))
                                }}
                                value={details?.stock}
                            />
                        </div>

                        <div className="col-span-3 sm:col-span-2 lg:col-span-2">
                            <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700">
                                Is Service
                            </label>
                            <Switch
                                checked={details?.service ?? false}
                                onChange={checked => {
                                    setDetails((prevState) => ({
                                        ...prevState,
                                        [`service`]: checked,
                                    }))
                                }}
                                className={`${details?.service ? 'bg-emerald-500' : 'bg-rose-500'
                                    } relative inline-flex h-6 w-11 items-center rounded-full mt-2`}
                            >
                                <span className="sr-only">Enable visibility</span>
                                <span
                                    className={`${details?.service ? 'translate-x-6' : 'translate-x-1'
                                        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                />
                            </Switch>
                        </div>

                        <div className="col-span-3 sm:col-span-2 lg:col-span-2">
                            <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700">
                                Is Training
                            </label>
                            <Switch
                                checked={details?.service ?? false}
                                onChange={checked => {
                                    setDetails((prevState) => ({
                                        ...prevState,
                                        [`training`]: checked,
                                    }))
                                }}
                                className={`${details?.training ? 'bg-emerald-500' : 'bg-rose-500'
                                    } relative inline-flex h-6 w-11 items-center rounded-full mt-2`}
                            >
                                <span className="sr-only">Enable visibility</span>
                                <span
                                    className={`${details?.training ? 'translate-x-6' : 'translate-x-1'
                                        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                />
                            </Switch>
                        </div>

                        <div className="col-span-9 sm:col-span-6 lg:col-span-6">
                            {
                                details?.training == true ?
                                    <MultipleDatePicker className="block w-full rounded border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        onSubmit={handleDates}
                                    /> : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </>
}
