function convertDateToString(date) {
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ]
    const days = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
    ]
    let new_date = new Date(date)
    return `${new_date.getDate()} ${months[new_date.getMonth()]} ${new_date.getFullYear()}`
}

function convertFullDateToString(date) {
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ]
    const days = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
    ]
    let new_date = new Date(date)
    return `${days[new_date.getDay()]}, ${new_date.getDate()} ${months[new_date.getMonth()]} ${new_date.getFullYear()}, ${new_date.getUTCHours()}:${new_date.getUTCMinutes() < 10 ? '0' : ''}${new_date.getUTCMinutes()}`
}

function convertFullDateToStringNoTime(date) {
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ]
    const days = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
    ]
    let new_date = new Date(date)
    return `${days[new_date.getDay()]}, ${new_date.getDate()} ${months[new_date.getMonth()]} ${new_date.getFullYear()}`
}

function convertSendableDateToString(date) {
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ]
    let new_date = new Date(date)
    return `${new_date.getDate()} ${months[new_date.getMonth()]} ${new_date.getFullYear()} ${new_date.getUTCHours()}:${new_date.getUTCMinutes() < 10 ? '0' : ''}${new_date.getUTCMinutes()}`
}


const formatCurrency = number => {
    try {
        return number === undefined ? number : `R${number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
    } catch (error) {
        console.error(error);
        return number
    }

}

function formatPhoneNumber(phoneNumberString) {
    let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        let intlCode = (match[1] ? '+1 ' : '');
        return [intlCode, match[2], ' ', match[3], ' ', match[4]].join('');
    }
    return null;
}

function NextBusinessDay(date) {
    date = new Date(+date);
    do {
        date.setDate(date.getDate() + 1);
    } while (!(date.getDay() % 6))
    return date;
}

function sumAddOn(service) {
    return service?.add_on_costs.reduce((accumulator, object) => {
        return accumulator + parseFloat(object.percentage ? parseFloat(service?.price) * (parseFloat(object.cost) / 100) : object.cost)
    }, 0)
}

function getVat(addons, price) {
    return (addons + price) * 0.15
}

function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1);  // deg2rad below
    const dLon = deg2rad(lon2 - lon1);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distance in km
    return distance;
}

function deg2rad(deg) {
    return deg * (Math.PI/180)
}
function Guid() {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

export const useUtils = () => {
    return {
        convertDateToString,
        formatPhoneNumber,
        formatCurrency,
        NextBusinessDay,
        sumAddOn,
        getVat,
        convertFullDateToString,
        convertSendableDateToString,
        getDistanceFromLatLonInKm,
        Guid,
        convertFullDateToStringNoTime
    }
}
